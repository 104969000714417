
/* eslint-disable vue/no-unused-components */
import { defineComponent, ref, onMounted, watch} from "vue";
import TImageDecorSplash from '@/components/TImageDecorSplash.vue'
import {backEndURL} from '@/siteConfig'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import CallForAction from '@/components/CallForAction.vue'
import pageData from '@/pageData/research'

export default defineComponent({
    props: {
        headImage: {
            default: "",
        },
        currTab:{default: ""}
    },
    components:{
        TImageDecorSplash, 
        CallForAction
    },
    setup(props){

        const articleTitle = ref('')
        const metaInfo = ref('')
        const articleDOM = ref<HTMLElement>(document.createElement('div'))
        const route = useRoute()
        const recommend = ref<any[]>([])

        const getRandom = (count:number, arr:any[]) => {
            if(count >= arr.length) return arr
            const out = [] as number[]
            for(let i = 0; i < count; i++){
                let ind = Math.floor(Math.random() * arr.length)
                /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
                while(1){
                    if(!out.includes(ind)){
                        out.push(ind)
                        break
                    }else{
                        ind = Math.floor(Math.random() * arr.length)
                    }
                }
            }
            return out.map(x=>arr[x])
        }

        const content_link = ref('')
        const content_image = ref('')
        const content_images = ref('')

        const init = (id:string)=>{
            axios.get(`${backEndURL}/public/article?category=${route.query.category}&id=${id}`)
            .then(res=>{
                content_link.value = res.data.link
                content_image.value = res.data.content_image
                content_images.value = res.data.content_images
                articleTitle.value = res.data.title
                articleDOM.value.innerHTML = res.data.content
                Array.from(articleDOM.value.querySelectorAll('p')).forEach(p=>{
                    p.style.position = 'relative'
                    p.style.paddingBottom = '20px'
                    p.style.lineHeight = '25px'
                    p.style.width = '100%'
                    p.style.fontSize = '16px'
                    p.style.lineHeight = '28px'
                    p.style.fontWeight = '300'
                })
                metaInfo.value = `华美顾问 发布于 ${res.data.datetime.split('T')[0]}`
                const imgs = Array.from(articleDOM.value.querySelectorAll('img'))
                imgs.forEach((p, i)=>{
                    p.style.position = 'relative'
                    p.style.objectFit = 'contain'
                    p.style.width = '100%'
                    // p.style.padding = i >= imgs.length-2 ? '0%' :'10%' 
                    p.style.paddingBottom = '20px'
                    // p.style.paddingTop = '50px'
                    if(p.src && (!p.src.includes('oss.huamei2001.com') && p.src.includes('/uploads'))){
                        p.src = 'https://oss.huamei2001.com' + p.src.slice(p.src.indexOf('/uploads'), p.src.length)
                    }
                })

                const waitGetArticles = setInterval(()=>{
                    if(pageData.value.inited){
                        recommend.value = getRandom(6, pageData.value.allNews)
                        clearInterval(waitGetArticles)
                    }
                }, 500)
            })
            .catch(err=>{
                return
            })
        }
        onMounted(()=>init(route.query.id as string))

        watch(()=>route.query.id as string, ()=>init(route.query.id as string))


        return {
            route,
            content_link,
            content_image,
            content_images,
            articleTitle,
            metaInfo,
            articleDOM,
            recommend,
            router:useRouter(),
            init,
        }
    }
});
